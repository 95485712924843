/* eslint-disable react/no-multi-comp */
import ArrowDown from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React, { type KeyboardEventHandler, useRef } from 'react';
import TinyCollapse from 'react-tiny-collapse';
import Banner from 'sats-ui-lib/react/banner';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import useToggle from 'client/hooks/use-toggle';
import ClubElement from 'components/club-element/club-element';
import ContentContainer from 'components/content-container/content-container';
import CurrentHomeClubCard from 'components/current-home-club-card/current-home-club-card';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type {
  ChangeHomeClubOverviewPage as Props,
  TemporaryExpander as TemporaryExpanderProps,
} from './change-home-club-overview-page.types';

const TemporaryExpander: React.FunctionComponent<TemporaryExpanderProps> = ({
  close,
  clubs,
  id,
  open,
  title,
}) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [isOpen, toggle, , turnOff] = useToggle(false);

  const handleKeyDown: KeyboardEventHandler = event => {
    if (event.code === 'Escape') {
      turnOff();
      if (triggerRef.current) {
        triggerRef.current.focus();
      }
    }
  };

  return (
    <div
      className="change-home-club-overview-page__temporary-expander"
      onKeyDown={handleKeyDown}
    >
      <button
        aria-controls={id}
        className="change-home-club-overview-page__temporary-expander-trigger"
        onClick={toggle}
        ref={triggerRef}
        title={isOpen ? close : open}
      >
        <Text elementName="h3" size={Text.sizes.basic}>
          {title}
        </Text>
        <div
          className={cn(
            'change-home-club-overview-page__temporary-expander-icon',
            {
              'change-home-club-overview-page__temporary-expander-icon--flipped':
                isOpen,
            }
          )}
        >
          <ArrowDown />
        </div>
      </button>
      <div id={id}>
        <TinyCollapse isOpen={isOpen}>
          <div className="change-home-club-overview-page__temporary-expander-content">
            {clubs.map(club => (
              <ClubElement key={club.name} {...club} small />
            ))}
          </div>
        </TinyCollapse>
      </div>
    </div>
  );
};

const ChangeHomeClubOverviewPage: React.FunctionComponent<Props> = ({
  currentClub,
  intro,
  myPageLayout,
  previous,
  selectableClubs,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="change-home-club-overview-page">
        {currentClub ? <CurrentHomeClubCard {...currentClub} /> : null}
        <Banner icon list={[]} text={intro} type="information" />
        {selectableClubs ? (
          <div className="change-home-club-overview-page__clubs">
            <div className="change-home-club-overview-page__clubs-title">
              <Text
                elementName="h2"
                size={Text.sizes.large}
                theme={Text.themes.emphasis}
              >
                {selectableClubs.title}
              </Text>
            </div>
            <div>
              {selectableClubs.regions.map(region => (
                <div key={region.key}>
                  <TemporaryExpander {...region.expander} />
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div className="change-home-club-overview-page__actions">
          <LinkButton {...previous} variant={LinkButton.variants.secondary} />
        </div>
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default ChangeHomeClubOverviewPage;
