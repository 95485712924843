import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import ArrowLink from 'components/arrow-link/arrow-link';
import MyMembershipCard from 'components/my-membership-card/my-membership-card';
import Spinner from 'components/spinner/spinner';

import type { MyMembershipCards as Props } from './my-membership-cards.types';

const MyMembershipCards: React.FunctionComponent<Props> = ({
  actions,
  items,
  regretActions,
  title,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return items.length ? (
    <div className="my-membership-cards">
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      {title ? (
        <div className="my-membership-cards__title">
          <Text
            elementName="h2"
            size={Text.sizes.headline2}
            theme={Text.themes.emphasis}
          >
            {title}
          </Text>
        </div>
      ) : null}
      {regretActions.map(action => (
        <div className="my-membership-cards__regret-action" key={action.key}>
          <div
            className="my-membership-cards__regret-action-icon"
            dangerouslySetInnerHTML={{ __html: action.rawIcon }}
          />
          <div className="my-membership-cards__regret-action-text">
            <Text elementName="h3" theme={Text.themes.emphasis}>
              {action.title}
            </Text>
            <Text elementName="p" size={Text.sizes.small}>
              {action.description}
            </Text>
          </div>
          <form
            className="my-membership-cards__regret-action-form"
            method="post"
            action={action.endpoint}
            onSubmit={handleSubmit}
          >
            {action.hiddenInputs.map(input => (
              <HiddenInput {...input} key={input.name} />
            ))}
            <Button
              {...action.button}
              type="submit"
              variant={LinkButton.variants.primary}
            />
          </form>
        </div>
      ))}
      <div className="my-membership-cards__items">
        {items.map(({ key, membership }) => (
          <div key={key}>
            <MyMembershipCard {...membership} />
          </div>
        ))}
      </div>
      {actions.length ? (
        <div className="my-membership-cards__actions">
          {actions.map(link => (
            <ArrowLink {...link} key={link.href} secondary small />
          ))}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default MyMembershipCards;
