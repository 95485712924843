import { useRef } from 'react';

import scrollingElement from 'client/helpers/scrolling-element';

import useEvent from './use-event';

/** Calls the provided callback with the current scroll position and a position delta each time a scroll event occurs */
export default (callback: (current: number, delta: number) => void) => {
  const prevPos = useRef<number>();

  useEvent('scroll', () => {
    const current = scrollingElement?.scrollTop;
    if (current === undefined) {
      return;
    }
    if (prevPos.current === undefined) {
      prevPos.current = current;
    }
    const delta = current - prevPos.current;
    callback(current, delta);
    prevPos.current = current;
  });
};
