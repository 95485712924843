import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Modal from 'sats-ui-lib/react/modal';
import Text from 'sats-ui-lib/react/text';
import VisuallyButton from 'sats-ui-lib/react/visually-button';
import { variants } from 'sats-ui-lib/react/visually-button/visually-button.types';

import ChangeMembershipCard from 'components/change-membership-card/change-membership-card';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import Spinner from 'components/spinner/spinner';

import type { ChangeMembershipConfirmPage as Props } from './change-membership-confirm-page.types';

const ChangeMembershipConfirmPage: React.FunctionComponent<Props> = ({
  cancel,
  cancelLabel,
  cancelPrompt,
  confirmButton,
  denyCancelMembershipChanges,
  endpoint,
  feeLabel,
  hiddenInputs,
  membershipCards,
  myPageLayout,
  modal,
  newPrice,
}) => {
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <MyPageLayout {...myPageLayout}>
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      <ContentContainer>
        <div className="change-membership-confirm-page">
          <div className="change-membership-confirm-page__card-container">
            {membershipCards &&
              membershipCards.map(card => (
                <ChangeMembershipCard key={card.title} {...card} />
              ))}
          </div>
          <div className="change-membership-confirm-page__price-container">
            <div className="change-membership-confirm-page__price">
              <Text
                className="change-membership-confirm-page__price--label"
                size={Text.sizes.small}
              >
                {feeLabel}
              </Text>
              <div className="change-membership-confirm-page__price--fee">
                <Text
                  className="change-membership-confirm-page__price--amount"
                  size={Text.sizes.headline3}
                  theme={Text.themes.headline}
                  italic
                  data-test-price
                >
                  {newPrice?.amount}
                </Text>
                <Text
                  className="change-membership-confirm-page__price--period"
                  size={Text.sizes.small}
                >
                  {newPrice?.period}
                </Text>
              </div>
            </div>
            <form method="post" action={endpoint} onSubmit={handleSubmit}>
              {hiddenInputs.map(input => (
                <HiddenInput key={input.name} {...input} />
              ))}

              <Button
                data-test-confirm
                type="submit"
                variant={Button.variants.cta}
                wide
                {...confirmButton}
              />
            </form>
            {show ? (
              <Modal onClose={() => setShow(false)} {...modal}>
                <div className="change-membership-confirm-page__modal">
                  <Text theme={Text.themes.normal} size={Text.sizes.basic}>
                    {cancelPrompt}
                  </Text>
                  <div>
                    <LinkButton
                      {...cancel}
                      variant={VisuallyButton.variants.primary}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={() => setShow(false)}
                      text={denyCancelMembershipChanges}
                      variant={variants.secondary}
                    />
                  </div>
                </div>
              </Modal>
            ) : null}
            <Button
              text={cancelLabel}
              className="my-workout-sessions-page__show-modal-button"
              variant={variants.secondary}
              onClick={() => setShow(true)}
            />
          </div>
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default ChangeMembershipConfirmPage;
