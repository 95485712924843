import React from 'react';

import LinkCard from 'sats-ui-lib/react/link-card';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import ImageLinkCard from 'components/image-link-card/image-link-card';
import Layout from 'components/layout/layout';

import type { CorporateMembershipPage as Props } from './corporate-membership-page.types';

const CorporateMembershipPage: React.FunctionComponent<Props> = ({
  articles,
  description,
  files,
  headerImage,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="corporate-membership-page">
      {headerImage ? (
        <ContentContainer>
          <DynamicImage
            aspectRatio={DynamicImage.aspectRatios.sixteenNine}
            {...headerImage}
          />
        </ContentContainer>
      ) : null}
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div className="corporate-membership-page__content">
          <Text
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.headline}
            italic
            className="corporate-membership-page__title"
          >
            {title}
          </Text>
          {description ? <Text elementName="p">{description}</Text> : null}
          {articles.map(article => (
            <ImageLinkCard
              {...article}
              key={article.title}
              headingSize={ImageLinkCard.headingSizes.medium}
              headingTheme={ImageLinkCard.headingThemes.emphasis}
            />
          ))}
          {files.map(file => (
            <LinkCard {...file} key={file.href} />
          ))}
        </div>
      </ContentContainer>
    </div>
  </Layout>
);

export default CorporateMembershipPage;
