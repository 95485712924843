import ArrowDown from '@sats-group/icons/24/arrow-down';
import Mail from '@sats-group/icons/24/mail';
import React, { useState } from 'react';
import Collapse from 'react-tiny-collapse';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';
import TextArea from 'sats-ui-lib/react/text-area';
import TextInput from 'sats-ui-lib/react/text-input';

import { mod } from 'client/helpers/add-bem-modifiers';
import useToggle from 'client/hooks/use-toggle';
import InputButton from 'components/input-button/input-button';

import type { ContactForm as Props } from './contact-option.types';

const ContactOption: React.FunctionComponent<Props> = ({
  action,
  body,
  cancel,
  description,
  files,
  hiddenInputs,
  open = true,
  submit,
  textInputs,
  subtitle,
  title,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, toggle] = useToggle(open);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files).map(file => file.name);
      setUploadedFiles([...files]);
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    if (isLoading || !(event.target as HTMLFormElement).checkValidity()) {
      event.preventDefault();
      return;
    }
    setIsLoading(true);
  };

  return (
    <div className="contact-option">
      <Text size={Text.sizes.headline3} theme={Text.themes.emphasis}>
        {title}
      </Text>
      <div className="contact-option__collapse">
        <button
          className="contact-option__trigger"
          data-testid="open-form"
          onClick={toggle}
        >
          <div className="contact-option__trigger-title">
            <Mail />
            <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
              {subtitle}
            </Text>
          </div>
          <div
            className={mod('contact-option__trigger-icon', {
              flipped: isOpen,
            })}
          >
            <ArrowDown />
          </div>
        </button>
        <Collapse isOpen={isOpen}>
          <div>
            <Text className="contact-option__form-description">
              {description}
            </Text>
            <form
              action={action}
              className="contact-option__form"
              data-testid="form"
              method="post"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              {hiddenInputs.map(input => (
                <HiddenInput {...input} key={input.name} />
              ))}
              <TextArea {...body} />
              {textInputs.length ? (
                <div className="contact-option__text-inputs">
                  {textInputs.map(input => (
                    <TextInput {...input} key={input.name} />
                  ))}
                </div>
              ) : null}
              <div className="contact-option__attachments">
                <Text theme={Text.themes.emphasis}>{files.title}</Text>
                {files.description ? (
                  <Text elementName="p">{files.description}</Text>
                ) : null}
                <div className="contact-option__attachments-inputs">
                  <div>
                    <InputButton
                      {...files.inputButton.button}
                      type="file"
                      onChange={handleUpload}
                      multiple
                      required={files.required}
                    >
                      {files.inputButton.label}
                    </InputButton>
                  </div>

                  {uploadedFiles.length > 0 ? (
                    <div>
                      {uploadedFiles.map(file => (
                        <Text
                          className="contact-option__attachment-name"
                          key={file}
                        >
                          {file}
                        </Text>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="contact-option__form-actions">
                <LinkButton
                  {...cancel}
                  variant={LinkButton.variants.secondary}
                />
                <div>
                  <Button
                    disabled={isLoading ? true : undefined}
                    text={submit}
                    theme={isLoading ? 'spinner' : undefined}
                    type="submit"
                    variant={Button.variants.cta}
                  />
                </div>
              </div>
            </form>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ContactOption;
