import IconForward from '@sats-group/icons/16/forward';
import React from 'react';

import Tag from 'sats-ui-lib/react/tag';
import Text from 'sats-ui-lib/react/text';
import VisuallyButton from 'sats-ui-lib/react/visually-button';

import DynamicImage from 'components/dynamic-image/dynamic-image';

import type { LinkBox as Props } from './link-box.types';

const LinkBox: React.FunctionComponent<Props> = ({
  href,
  id,
  image,
  label,
  tags,
  title,
}) => (
  <a aria-label={label} className="link-box" data-id={id} href={href}>
    <div className="link-box__image">
      {image ? (
        <DynamicImage
          aspectRatio={DynamicImage.aspectRatios.fourThree}
          {...image}
        />
      ) : null}
    </div>
    <div className="link-box__data">
      <Text className="link-box__title" theme={Text.themes.emphasis}>
        {title}
      </Text>
      {tags.length ? (
        <div className="link-box__tags">
          {tags.map(tag => (
            <div key={tag.text}>
              <Tag {...tag} border={false} size="small" variant="secondary" />
            </div>
          ))}
        </div>
      ) : null}
    </div>
    <div className="link-box__overlay">
      <div>
        <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
          {label}
        </Text>
      </div>
      <div>
        <VisuallyButton
          ariaLabel={label}
          elementName="div"
          icon={<IconForward />}
          size="small"
          variant="secondary-white"
        />
      </div>
    </div>
  </a>
);

export default LinkBox;
