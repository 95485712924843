import { useCallback } from 'react';

import useEvent from './use-event';

const useEscape = (callback: () => void) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        callback();
      }
    },
    [callback]
  );

  useEvent('keydown', handleKeyDown);
};

export default useEscape;
