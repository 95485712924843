import StarFilled from '@sats-group/icons/24/star';
import Star from '@sats-group/icons/24/star-border';
import cn from 'classnames';
import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';

import Spinner from 'components/spinner/spinner';
import { useFavoriteContext } from 'contexts/favorite-context-provider';
import useIsMountedRef from 'hooks/use-is-mounted-ref';

import type { FavoriteButton as Props } from './favorite-button.types';

const FavoriteButton: React.FunctionComponent<Props> = ({
  addText,
  id,
  removeText,
  type,
}) => {
  const isMountedRef = useIsMountedRef();
  const { isFavorite, toggleFavorite } = useFavoriteContext();
  const isFav = isFavorite(id);
  const [isLoading, setIsLoading] = useState(false);
  const label = isFav ? removeText : addText;

  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    toggleFavorite(id, type).finally(() => {
      // NOTE: This component may unmount before this Promise is resolved.
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="favorite-button">
      <Button
        ariaLabel={label}
        className={cn('favorite-button__button', {
          'favorite-button__button--active': isFav,
        })}
        data-test-favorite-button={isFav}
        onClick={onClick}
        icon={isLoading ? <Spinner /> : isFav ? <StarFilled /> : <Star />}
      />
      {label}
    </div>
  );
};

export default FavoriteButton;
