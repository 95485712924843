import cn from 'classnames';
import React from 'react';
import Collapse from 'react-tiny-collapse';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Logo from 'components/logo/logo';
import useToggle from 'hooks/use-toggle';

import MenuDropdown from './menu-dropdown';
import type { Menu as Props } from './menu.types';

const MenuItem: React.FunctionComponent<Props['primaryItems'][number]> = ({
  kind,
  props,
}) => (
  <div className="menu__item">
    {kind === 'dropdown-item' ? (
      <MenuDropdown {...props} />
    ) : (
      <a className="menu__item-link" href={props.href}>
        <Text elementName="span" size={Text.sizes.small}>
          {props.text}
        </Text>
      </a>
    )}
  </div>
);

const drawerId = 'unique-drawer-id';

// eslint-disable-next-line react/no-multi-comp
const Menu: React.FunctionComponent<Props> = ({
  closeLabel,
  dropdownShortcut,
  logo,
  navigationLandmarkLabel,
  openLabel,
  primaryItems,
  secondaryItems,
  shortcut,
  shortcuts,
}) => {
  const [isOpen, toggle] = useToggle(false);

  const hasItems = Boolean(primaryItems.length + secondaryItems.length);

  return (
    <div className="menu">
      <ContentContainer theme={ContentContainer.themes.wide}>
        <div className="menu__content">
          <div className="menu__logo-area">
            <Logo {...logo} color={Logo.colors.light} />
          </div>
          <div className="menu__trigger-area">
            <LinkButton size={LinkButton.sizes.small} {...shortcut} />
            {hasItems ? (
              <button
                aria-controls={drawerId}
                aria-expanded={isOpen}
                aria-label={isOpen ? closeLabel : openLabel}
                className={cn('menu__trigger', {
                  'menu__trigger--active': isOpen,
                })}
                onClick={toggle}
              >
                <svg width={32} height={32} viewBox="0 0 32 32">
                  <path d="M2,8 L30,8 M2,24 L30,24" />
                </svg>
              </button>
            ) : null}
          </div>
          <div className="menu__links-area">
            <Collapse
              componentProps={{ id: drawerId }}
              isOpen={isOpen}
              className="menu__drawer"
              animateChildren={false}
            >
              <div>
                <nav
                  aria-label={navigationLandmarkLabel}
                  className="menu__items"
                >
                  {primaryItems.map(item => (
                    <MenuItem
                      {...item}
                      key={
                        item.kind === 'dropdown-item'
                          ? item.props.title
                          : item.props.href
                      }
                    />
                  ))}
                  {primaryItems.length && secondaryItems.length ? (
                    <div
                      aria-hidden
                      className="menu__item-separator menu__item-separator--vertical"
                    />
                  ) : null}
                  {secondaryItems.map(item => (
                    <MenuItem
                      {...item}
                      key={
                        item.kind === 'dropdown-item'
                          ? item.props.title
                          : item.props.href
                      }
                    />
                  ))}
                </nav>
                <div
                  aria-hidden
                  className="menu__item-separator menu__item-separator--horizontal"
                />
                <div className="menu__dropdown-shortcut">
                  <a
                    className="menu__item-link menu__item-link--shortcut"
                    href={dropdownShortcut.href}
                  >
                    {dropdownShortcut.text}
                  </a>
                </div>
              </div>
            </Collapse>
          </div>
          <div className="menu__shortcuts">
            {shortcuts.map(link => (
              <LinkButton
                key={link.href}
                variant={LinkButton.variants.ctaSecondaryWhite}
                size={LinkButton.sizes.small}
                {...link}
              />
            ))}
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default Menu;
