import React from 'react';

import LinkCard from 'sats-ui-lib/react/link-card';
import Text from 'sats-ui-lib/react/text';

import Author from 'components/author/author';
import ContentContainer from 'components/content-container/content-container';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import ImageLinkCard from 'components/image-link-card/image-link-card';
import Layout from 'components/layout/layout';
import RichText from 'components/rich-text/rich-text';

import type { CorporateArticlePage as Props } from './corporate-article-page.types';

const CorporateArticlePage: React.FunctionComponent<Props> = ({
  author,
  introduction,
  layout,
  mainImage,
  pdfs,
  related,
  text,
  title,
  webLinks,
}) => (
  <Layout {...layout}>
    <div className="corporate-article-page">
      {mainImage ? (
        <ContentContainer>
          <div className="corporate-article-page__header-image">
            <DynamicImage
              aspectRatio={DynamicImage.aspectRatios.sixteenNine}
              {...mainImage}
            />
          </div>
        </ContentContainer>
      ) : null}
      <ContentContainer theme={ContentContainer.themes.medium}>
        <div className="corporate-article-page__content">
          <Text
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
            italic
            className="corporate-article-page__title"
          >
            {title}
          </Text>
          {introduction ? (
            <Text elementName="p" size={Text.sizes.large}>
              {introduction}
            </Text>
          ) : null}
          {author ? <Author {...author} /> : null}
          {text ? <RichText {...text} /> : null}
          {related.length ? (
            <div className="corporate-article-page__related">
              {related.map(link => (
                <ImageLinkCard
                  key={link.href}
                  {...link}
                  headingSize={ImageLinkCard.headingSizes.medium}
                  headingTheme={ImageLinkCard.headingThemes.emphasis}
                />
              ))}
            </div>
          ) : null}
          {webLinks.length ? (
            <div className="corporate-article-page__link-container">
              {webLinks.map(link => (
                <LinkCard key={link.href} {...link} />
              ))}
            </div>
          ) : null}
          {pdfs.length ? (
            <div className="corporate-article-page__link-container">
              {pdfs.map(pdf => (
                <LinkCard key={pdf.href} {...pdf} />
              ))}
            </div>
          ) : null}
        </div>
      </ContentContainer>
    </div>
  </Layout>
);

export default CorporateArticlePage;
