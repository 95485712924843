import SvgPlay from '@sats-group/icons/24/play';
import cn from 'classnames';
import React from 'react';

import Modal from 'sats-ui-lib/react/modal';
import Text from 'sats-ui-lib/react/text';
import VisuallyButton from 'sats-ui-lib/react/visually-button';

import useToggle from 'hooks/use-toggle';

import DynamicImage from '../dynamic-image/dynamic-image';
import YoutubePlayer from '../youtube-player/youtube-player';

import type { VideoCard as Props } from './video-card.types';

/** SPEC: https://www.figma.com/file/dQeQgh6sANMhWBUNtgXQNN/satsweb-components?node-id=2242-23231 */
const VideoCard: React.FunctionComponent<Props> = ({
  image,
  tall,
  title,
  video,
}) => {
  const [isOpen, , open, close] = useToggle(false);

  return (
    <div className={cn('video-card', { 'video-card--tall': tall })}>
      <button className="video-card__image" onClick={open}>
        <DynamicImage
          {...image}
          aspectRatio={DynamicImage.aspectRatios.square}
        />
        <div aria-hidden="true" className="video-card__flourish">
          <VisuallyButton
            ariaLabel=""
            elementName="div"
            icon={<SvgPlay />}
            size={VisuallyButton.sizes.large}
            variant={VisuallyButton.variants.ctaSecondaryWhite}
          />
        </div>
      </button>
      <Text size={Text.sizes.large}>{title}</Text>
      {isOpen ? (
        <Modal
          ariaLabel=""
          background="primary"
          closeLabel=""
          id={video.id}
          onClose={close}
          title={title}
        >
          <YoutubePlayer {...video} />
        </Modal>
      ) : null}
    </div>
  );
};

export default VideoCard;
