import React from 'react';

import Text from 'sats-ui-lib/react/text';

import type { Progressbar as Props } from './progressbar.types';

const ProgressBar: React.FunctionComponent<Props> = ({
  value,
  max,
  labelText,
}) => (
  <label>
    <progress className="progress-bar__progress" value={value} max={max} />
    <Text className="progress-bar__text">{labelText}</Text>
  </label>
);

export default ProgressBar;
