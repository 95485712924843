import type { ChangeEvent, FormEvent } from 'react';
import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import useToggle from 'hooks/use-toggle';

import type { DiscountCodeInput as Props } from './discount-code-input.types';

const DiscountCodeInput: React.FunctionComponent<Props> = ({
  appliedCode,
  submitLabel,
  removeLabel,
  input,
  placeholder,
  successHeader,
  successBody,
  isLoading = false,
  onClick = () => {},
}) => {
  const [shouldShowGatekeeper, , , hideGatekeeper] = useToggle(
    appliedCode ? false : true
  );
  const [enteredCode, setEnteredCode] = useState(
    appliedCode ? appliedCode.value : ''
  );
  const [isSubmitted, toggleIsSubmitted] = useToggle(
    appliedCode ? true : false
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    setEnteredCode(event.target.value);

  const submitCode = (newCode: string) => {
    if (isLoading) {
      return;
    }

    onClick(input.name, newCode);
    toggleIsSubmitted();
    setEnteredCode(newCode);
  };

  const setCode = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitCode(enteredCode);
  };

  const removeCode = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitCode('');
  };

  const shouldShowRemoval = shouldShowGatekeeper
    ? false
    : isSubmitted && enteredCode.length && enteredCode === appliedCode?.value;

  const shouldShowInput = shouldShowGatekeeper ? false : !shouldShowRemoval;

  return (
    <div className="discount-code-input">
      {shouldShowGatekeeper ? (
        <Button
          variant={Button.variants.secondary}
          onClick={hideGatekeeper}
          text={placeholder}
        />
      ) : null}
      {shouldShowInput ? (
        <form className="discount-code-input__form" onSubmit={setCode}>
          <TextInput
            hasMovingLabel={true}
            onChange={handleInputChange}
            {...input}
          />
          <Button
            text={submitLabel}
            type="submit"
            variant={Button.variants.primary}
          />
        </form>
      ) : null}
      {shouldShowRemoval ? (
        <form className="discount-code-input__success" onSubmit={removeCode}>
          <div>
            <Text theme={Text.themes.emphasis}>{successHeader}</Text>
            <Text>{`${appliedCode?.value} ${successBody}`}</Text>
            <Text>{appliedCode?.description.map(text => text)}</Text>
          </div>
          <Button
            variant={Button.variants.secondary}
            type="submit"
            text={removeLabel}
          />
        </form>
      ) : null}
    </div>
  );
};

export default DiscountCodeInput;
