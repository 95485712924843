import { sizes as TextSizes } from 'sats-ui-lib/react/text/text.types';

import type { ObjectValues } from 'client/types';

export const themes = {
  horizontal: 'horizontal',
  medium: 'medium',
  narrow: 'narrow',
  veryNarrow: 'very-narrow',
  wide: 'wide',
} as const;

export const colors = {
  darkOrange: 'dark-orange',
  orange: 'orange',
  light: 'light',
} as const;

export const sizes = {
  basic: TextSizes.basic,
  big: TextSizes.headline1,
  medium: TextSizes.headline2,
  small: TextSizes.small,
} as const;

export interface Price {
  amount: string;
  color?: ObjectValues<typeof colors>;
  currency?: string;
  label?: string;
  period?: string;
  size?: ObjectValues<typeof sizes>;
  theme?: ObjectValues<typeof themes>;
}
