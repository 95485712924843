import type { ObjectValues } from 'client/types';

export const colors = {
  dark: 'dark',
  light: 'light',
} as const;

export const brands = {
  sats: 'sats',
  elixia: 'elixia',
} as const;

export type Logo = {
  color?: ObjectValues<typeof colors>;
  brand?: ObjectValues<typeof brands>;
  href?: string;
  label?: string;
};
