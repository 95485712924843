import Check from '@sats-group/icons/16/check';
import React from 'react';

import Text from 'sats-ui-lib/react/text';

import Price from 'components/price/price';

import type { RadioCard as Props } from './radio-card.types';

const RadioCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  details,
  detailsList,
  label,
  name,
  onClick = () => {},
  price,
  required,
  value,
  isChecked,
  children,
}) => (
  <div className="radio-card">
    <label className="radio-card__label">
      <input
        className="radio-card__radio-input"
        type="radio"
        id={label}
        name={name}
        value={value}
        required={required}
        data-test-radio-card
        onChange={() => onClick()}
        checked={isChecked}
      ></input>
      <div className="radio-card__check">
        <Check />
      </div>
      {label}
    </label>

    {price ? (
      <div className="radio-card__price">
        <Price
          color={Price.colors.orange}
          size={Price.sizes.medium}
          {...price}
        />
      </div>
    ) : null}

    {children ? (
      <div className="radio-card__children-container">{children}</div>
    ) : null}

    {details ? (
      <Text className="radio-card__details" size={Text.sizes.small}>
        {details}
      </Text>
    ) : null}
    {detailsList ? (
      <div className="radio-card__detailsList">
        {detailsList.map(detail => (
          <Text key={detail} size={Text.sizes.small}>
            {detail}
          </Text>
        ))}
      </div>
    ) : null}
  </div>
);

export default RadioCard;
