import React from 'react';

import Expander from 'sats-ui-lib/react/expander';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import PaymentReceiptGroup from 'components/payment-receipt-group/payment-receipt-group';

import type { MyPagePayments as Props } from './my-page-payments.types';

const MyPagePayments: React.FunctionComponent<Props> = ({
  myPageLayout,
  payments,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      {payments.map(payment => (
        <Expander
          {...payment.props}
          itemRenderer={PaymentReceiptGroup}
          key={payment.key}
        />
      ))}
    </ContentContainer>
  </MyPageLayout>
);

export default MyPagePayments;
