import type React from 'react';

import useEvent from './use-event';

/** Executes the provided callback when a click event occurs outside of the provided ref */
const useClickOutside = (
  ref: React.RefObject<Element | null>,
  callback: () => void = () => {}
): void => {
  useEvent<MouseEvent>('mousedown', e => {
    if (
      ref.current &&
      ref?.current !== e.target &&
      // NOTE: Because of react typings we need to cast this, more info: https://stackoverflow.com/a/61164277
      !ref.current.contains(e.target as Node) &&
      e.clientX < document.body.scrollWidth
    ) {
      callback();
    }
  });
};

export default useClickOutside;
