import SvgArrowLeft from '@sats-group/icons/18/arrow-left';
import SvgArrowRight from '@sats-group/icons/18/arrow-right';
import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Text from 'sats-ui-lib/react/text';

import clamp from 'client/helpers/clamp';

import type { Carousel as Props } from './carousel.types';

/** SPEC: https://www.figma.com/file/dQeQgh6sANMhWBUNtgXQNN/satsweb-components?node-id=2231-20963 */
const Carousel = <T extends { tall?: boolean }>({
  description,
  entries,
  entryRenderer,
  id,
  labels,
  title,
}: Props<T> & { entryRenderer: React.FC<T> }) => {
  const [leftPercentage, setLeftPercentage] = useState(0);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [rightPercentage, setRightPercentage] = useState(0);
  const [scrollbarLeft, setScrollbarLeft] = useState(0);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const setPercentages = (
    clientWidth: number,
    scrollLeft: number,
    scrollWidth: number
  ) => {
    if (!clientWidth) {
      setLeftPercentage(0);
      setRightPercentage(0);
      setScrollPercentage(0);
      setScrollbarWidth(0);
      setScrollbarLeft(0);
      return;
    }

    const sPercentage = (scrollLeft / (scrollWidth - clientWidth)) * 100;
    const lPercentage = (scrollLeft / scrollWidth) * 100;
    const rPercentage = ((scrollLeft + clientWidth) / scrollWidth) * 100;
    const barWidth = (clientWidth / scrollWidth) * 100;
    const barLeft = ((100 - barWidth) * sPercentage) / 100 / (barWidth / 100);

    setLeftPercentage(Number(lPercentage.toFixed(2)));
    setRightPercentage(Number(rPercentage.toFixed(2)));
    setScrollPercentage(Number(sPercentage.toFixed(2)));
    setScrollbarWidth(Number(barWidth.toFixed(2)));
    setScrollbarLeft(Number(barLeft.toFixed(2)));
  };

  const leftIntPercentage = useMemo(
    () => Math.floor(leftPercentage),
    [leftPercentage]
  );

  const rightIntPercentage = useMemo(
    () => Math.ceil(rightPercentage),
    [rightPercentage]
  );

  const canShowEverything = useMemo(
    () => leftIntPercentage === 0 && rightIntPercentage === 100,
    [leftIntPercentage, rightIntPercentage]
  );

  const highestVisibleIndex = useMemo(() => {
    if (!rightIntPercentage) {
      return 0;
    }

    return Math.floor((entries.length * rightIntPercentage) / 100 - 1);
  }, [entries, rightIntPercentage]);

  const lowestVisibleIndex = useMemo(() => {
    if (!leftIntPercentage) {
      return 0;
    }

    return Math.ceil((entries.length * leftIntPercentage) / 100);
  }, [entries, leftIntPercentage]);

  const view = useRef<HTMLDivElement>(null);
  const track = useRef<HTMLDivElement>(null);

  const goTo = (index: number) => {
    if (!track.current) {
      return;
    }

    const child = track.current.children.item(
      clamp(index, 0, entries.length - 1)
    );
    if (!child) {
      return;
    }

    child.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  const goToNext = () => goTo(highestVisibleIndex + 1);

  const goToPrevious = () => goTo(lowestVisibleIndex - 1);

  const goToPercentage = (percentage: number) => {
    if (!track.current) {
      return;
    }

    const numberOfChildren = Array.from(track.current.children).length;
    const index = Math.round((numberOfChildren * percentage) / 100);

    goTo(index);
  };

  const hasNext = useMemo(
    () => entries.length - 1 > highestVisibleIndex,
    [entries, highestVisibleIndex]
  );

  const hasPrevious = useMemo(
    () => Boolean(lowestVisibleIndex),
    [lowestVisibleIndex]
  );

  const handleResize: ResizeObserverCallback = entries =>
    entries.forEach(entry =>
      setPercentages(
        entry.target.clientWidth,
        entry.target.scrollLeft,
        entry.target.scrollWidth
      )
    );

  const handleScroll: React.UIEventHandler<HTMLDivElement> = e =>
    setPercentages(
      e.currentTarget.clientWidth,
      e.currentTarget.scrollLeft,
      e.currentTarget.scrollWidth
    );

  const handleScrollbarClick: React.MouseEventHandler<HTMLDivElement> = e => {
    const [rect] = Array.from(e.currentTarget.getClientRects());
    if (!rect) {
      return;
    }

    goToPercentage(((e.clientX - rect.x) / rect.width) * 100);
  };

  useEffect(() => {
    if (!view.current) {
      return;
    }

    setPercentages(
      view.current.clientWidth,
      view.current.scrollLeft,
      view.current.scrollWidth
    );

    const observer = new ResizeObserver(handleResize);
    observer.observe(view.current);

    return () => {
      if (view.current) {
        observer.unobserve(view.current);
      }
    };
  }, []);

  const EntryRenderer = entryRenderer;

  return (
    <div
      className={cn('carousel', { 'carousel--disabled': canShowEverything })}
    >
      <div className="carousel__content">
        <div className="carousel__text">
          <Text size={Text.sizes.headline2} theme={Text.themes.emphasis}>
            {title}
          </Text>
          {description ? <Text>{description}</Text> : null}
        </div>
        <div className="carousel__slider">
          <div
            className={cn('carousel__arrow carousel__arrow--previous', {
              'carousel__arrow--hidden': !hasPrevious,
            })}
          >
            <Button
              ariaLabel={labels.previous}
              icon={<SvgArrowLeft />}
              onClick={goToPrevious}
              size={Button.sizes.large}
              tabIndex={-1}
              variant={Button.variants.cta}
            />
          </div>
          <div className="carousel__entries">
            <div className="carousel__view" onScroll={handleScroll} ref={view}>
              <div
                className={cn('carousel__track', {
                  'carousel__track--centered': canShowEverything,
                })}
                id={id}
                ref={track}
              >
                {entries.map(entry => (
                  <div
                    className="carousel__entry"
                    data-key={entry.key}
                    key={entry.key}
                  >
                    <EntryRenderer {...entry.props} tall />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={cn('carousel__arrow carousel__arrow--next', {
              'carousel__arrow--hidden': !hasNext,
            })}
          >
            <Button
              ariaLabel={labels.next}
              icon={<SvgArrowRight />}
              onClick={goToNext}
              size={Button.sizes.large}
              tabIndex={-1}
              variant={Button.variants.cta}
            />
          </div>
        </div>
        <div
          aria-controls={id}
          aria-orientation="horizontal"
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={scrollPercentage}
          className={cn('carousel__scroll-area', {
            'carousel__scroll-area--hidden': canShowEverything,
          })}
          onClick={handleScrollbarClick}
          role="scrollbar"
        >
          <div className="carousel__scrollbar-wrapper">
            <div
              className="carousel__scrollbar"
              style={{
                transform: `translateX(${scrollbarLeft}%)`,
                width: `${scrollbarWidth}%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
