import ArrowDown from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React, { type KeyboardEventHandler, useRef } from 'react';
import Collapse from 'react-tiny-collapse';

import Text from 'sats-ui-lib/react/text';

import ClubElement from 'client/components/club-element/club-element';
import useToggle from 'client/hooks/use-toggle';

import type { CurrentHomeClubCard as Props } from './current-home-club-card.types';

const CurrentHomeClubCard: React.FunctionComponent<Props> = ({
  close,
  club,
  id,
  open,
  title,
}) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [isOpen, toggle, , turnOff] = useToggle(false);

  const handleKeyDown: KeyboardEventHandler = event => {
    if (event.code === 'Escape') {
      turnOff();
      if (triggerRef.current) {
        triggerRef.current.focus();
      }
    }
  };

  return (
    <div className="current-home-club-card" onKeyDown={handleKeyDown}>
      <button
        aria-controls={id}
        className="current-home-club-card__trigger"
        onClick={toggle}
        ref={triggerRef}
        title={isOpen ? close : open}
      >
        <Text
          elementName="div"
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
        >
          {title}
        </Text>
        <Text elementName="div" size={Text.sizes.basic}>
          {club.name}
        </Text>
        <div
          className={cn('current-home-club-card__icon', {
            'current-home-club-card__icon--flipped': isOpen,
          })}
        >
          <ArrowDown />
        </div>
      </button>
      <div id={id}>
        <Collapse isOpen={isOpen}>
          <div className="current-home-club-card__content">
            <ClubElement {...club} />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default CurrentHomeClubCard;
